import React, { useState, useCallback, useEffect } from 'react'
import logo from './assets/img/logo.png'
import logoRu from './assets/country-icons/RU.png'
import logoUk from './assets/country-icons/Ukraine.png'
import logoEn from './assets/country-icons/United-States.png'
import logoEs from './assets/country-icons/Spain.png'
import AOS from 'aos';
import ReactFullpage from '@fullpage/react-fullpage'
import Sections from './Components/Sections'
import { Modal, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import { IntlProvider, FormattedMessage } from "react-intl"
import translations from './translations.json'

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/main.css';

function App() {
  const [loading, setLoading] = useState(true)
  const [sectionPadding, setSectionPadding] = useState('101px')
  const [lengthSelection, setLengthSelection] = useState({ width: 0, margin: 0 })
  const [modal, setModal] = useState(false);
  const [modalThanks, setModalThanks] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen(prevState => !prevState);

  const [locale, setLocale] = useState(navigator.language.split(/[-_]/)[0]);
  const [countryLogo, setCountryLogo] = useState('')

  useEffect(() => {
    let startLogo = ''

    switch (locale) {
      case 'ru':
        startLogo = logoRu
        break
      case 'en': 
        startLogo = logoEn
        break
      case 'uk': 
        startLogo = logoUk
        break
      case 'es':
        startLogo = logoEs
        break
      default:
        startLogo = logoEn
    }

    setCountryLogo(startLogo)
  }, [locale])


  const toggle = () => setModal(!modal);
  const toggleThanks = () => setModalThanks(!modalThanks);

  const sendForm = () => {
    toggle()
    toggleThanks()
  }

  const navRef = useCallback(nav => {
    if (nav !== null) {
      setSectionPadding((nav.offsetHeight + 30) + 'px')
    }
  }, []);

  const containerRef = useCallback(container => {
    if (container !== null) {
      let w = container.offsetWidth

      w = w / 2 - 40
      
      setLengthSelection({ width: w, margin: 0 - w - 20 })
    }
  }, []);

  const changeLocale = (event) => {
    const locale = event.currentTarget.dataset.locale
    setLocale(locale)
  }

  const setLocation = () => {
    window.location.href = 'https://app.antifraudsms.com/'
  }
  
  window.onload = function() {
    setLoading(false)
    AOS.init()
  }

  if (loading) {
    return (
      <div className="preloader">
        <img src={logo} alt='AFSMS' />
      </div>
    )
  }

  let page = (
    <ReactFullpage
      //fullpage options
      sectionSelector='.vertical-scrolling'
      navigation={true}
      navigationPosition='left'
      controlArrows={true}
      verticalCentered={false}
      slidesNavigation={true}
      anchors={['firstSection', 'secondSection', 'thirdSection', 'fourthSection', 'fifthSection', 'sixthSection']}
      paddingTop={sectionPadding}

      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Sections fullpageApi={fullpageApi} toggle={toggle} lengthSelection={lengthSelection} containerRef={containerRef} />
          </ReactFullpage.Wrapper>
        );
      }}
    />
  )

  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent)) {
    page = (
      <Sections fullpageApi={null} toggle={toggle} lengthSelection={lengthSelection} containerRef={containerRef} />
    )
  }

  return (
    <>
      <IntlProvider locale={locale} messages={translations[locale]}>    
        <nav ref={navRef} className="navbar">
          <div className="container">
            <a className='main-logo'>
              <img src={logo} alt="" />
            </a>
            <form className="form-inline">
              <div className="button my-2 my-sm-0" onClick={setLocation}><FormattedMessage defaultMessage={translations['en']['header.button']} id="header.button" /></div>
              <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
                <DropdownToggle className="btn-circle ml-3 btn-main_country">
                  <img src={countryLogo} alt="" className='img-flag' />
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={changeLocale} data-locale='ru' className="btn-circle"><img src={logoRu} alt="" className='img-flag' /></DropdownItem>
                  <DropdownItem onClick={changeLocale} data-locale='en' className="btn-circle"><img src={logoEn} alt="" className='img-flag' /></DropdownItem>
                  <DropdownItem onClick={changeLocale} data-locale='uk' className="btn-circle"><img src={logoUk} alt="" className='img-flag' /></DropdownItem>
                  <DropdownItem onClick={changeLocale} data-locale='es' className="btn-circle"><img src={logoEs} alt="" className='img-flag' /></DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </form>
          </div>
        </nav>

        {page}

        <Modal centered isOpen={modal} toggle={toggle}>
              <div className="row justify-content-center align-items-center">
                <div className="col-12 col-md-10 col-lg-6 col-xl-9">
                  <div className="cont text-center">
                    <div className="title">
                      <FormattedMessage defaultMessage={translations['en']['popap.title']} id="popap.title" values={{ 
                        span: (...chunks) => <span>{chunks}</span>
                      }} />
                    </div>
                    <FormattedMessage defaultMessage={translations['en']['popap.input.name_placeholder']} id="popap.input.name_placeholder">
                      {(msg) => (<input type="text" name="name" id="name" value="" placeholder={msg} />)}
                    </FormattedMessage>
                    <FormattedMessage defaultMessage={translations['en']['popap.input.phone_placeholder']} id="popap.input.phone_placeholder">
                      {(msg) => (<input type="text" name="phone" id="phone" value="" placeholder={msg} />)}
                    </FormattedMessage>
                    <div className="button" onClick={sendForm}><FormattedMessage defaultMessage={translations['en']['popap.button']} id="popap.button" /></div>
                    <div className="policy"><FormattedMessage defaultMessage={translations['en']['popap.policy']} id="popap.policy" /></div>
                  </div>
                </div>
              </div>
        </Modal>

        <Modal centered isOpen={modalThanks} toggle={toggleThanks}>
          <div className="thanks">
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-10 col-lg-6 col-xl-9">
                <div className="cont">
                  <img src={logo} alt="" />
                  <p>
                    <span><FormattedMessage defaultMessage={translations['en']['graciousness.title']} id="graciousness.title" /></span><br />
                    <FormattedMessage defaultMessage={translations['en']['graciousness.title']} id="graciousness.description" />  
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </IntlProvider>
    </>    
  );
}

export default App;
