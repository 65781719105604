import React from 'react'
import macLogo from '../assets/img/mac_03.png'
import macLogo2 from '../assets/img/mac_01.png'
import workScheme from '../assets/img/scheme.png'
import imgI05 from '../assets/img/i_05.png'
import logo from '../assets/img/logo.png'
import translations from '../translations.json'
import { FormattedMessage } from "react-intl"

const Sections = ({ fullpageApi, lengthSelection, containerRef, toggle}) => {
    const moveSectionDown = () => {
      if (fullpageApi) {
        fullpageApi.moveSectionDown()
      }
    }

    return (
      <>
        <section className="vertical-scrolling" id='block_1'>
        <div ref={containerRef} className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-7 col-lg-5" data-aos="fade-right">
              <p>
                <span className="title">ANTIFRAUDSMS.com</span>
                <FormattedMessage id="first_block.description" defaultMessage={translations['en']['first_block.description']} values={{ 
                  span: (...chunks) => <span>{chunks}</span>
                }} />
              </p>
              <div className="button" onClick={toggle}><FormattedMessage defaultMessage={translations['en']['first_block.more']} id="first_block.more" /></div>
            </div>
          </div>
        </div>
        <div className="bg"></div>
        <div onClick={moveSectionDown} className="mouse">
          <div style={{ width: lengthSelection.width, left: lengthSelection.margin }} className="left"></div>
          <div style={{ width: lengthSelection.width, right: lengthSelection.margin }} className="right"></div>
        </div>
      </section>
  
      <section className="vertical-scrolling" id='block_2'>
        <div className="container">
          <h2 className="text-center"><FormattedMessage defaultMessage={translations['en']['second_block.title']} id="second_block.title" /></h2>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-md-10 col-xl-8 text-center">
              <p className="intro"><FormattedMessage defaultMessage={translations['en']['second_block.description']} id="second_block.description" /></p>
            </div>
            <div className="col-12"></div>
            <div className="col-12 col-lg-6">
              <div className="item i_01">
                <div className="icon"></div>
                <p><FormattedMessage defaultMessage={translations['en']['second_block.list.first']} id="second_block.list.first" /></p>
              </div>
              <div className="item i_02">
                <div className="icon"></div>
                <p>
                  <FormattedMessage defaultMessage={translations['en']['second_block.list.second']} id="second_block.list.second" values={{ 
                    span: (...chunks) => <span>{chunks}</span>
                  }} />
                </p>
              </div>
              <div className="item i_03">
                <div className="icon"></div>
                <p>
                  <FormattedMessage defaultMessage={translations['en']['second_block.list.third']} id="second_block.list.third" values={{ 
                    span: (...chunks) => <span>{chunks}</span>
                  }} />
                </p>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <img src={macLogo} className="img-responsive" alt="" />
            </div>
          </div>
        </div>
        <div onClick={moveSectionDown} className="mouse">
          <div style={{ width: lengthSelection.width, left: lengthSelection.margin }} className="left"></div>
          <div style={{ width: lengthSelection.width, right: lengthSelection.margin }} className="right"></div>
        </div>
      </section>
  
      <section className="vertical-scrolling" id='block_3'>
        <div className="container">
  
          <div className="row align-items-center justify-content-center">
  
            <div className="col-12">
              <h2 className="text-center">
                <FormattedMessage defaultMessage={translations['en']['third_block.title']} id="third_block.title" values={{ 
                  span: (...chunks) => <span>{chunks}</span>
                }} />
              </h2>
            </div>
  
            <div className="col-12 col-xl-5 d-none d-xl-block">
              <img className="img-responsive" src={macLogo2} alt="" />
            </div>
  
            <div className="col-12 col-lg-12 col-xl-7">
  
              <div className="row row-flex">
                <div className="col-12">
                  <div className="item">
                    <div className="icon"></div>
                    <p>
                      <FormattedMessage defaultMessage={translations['en']['third_block.list.first']} id="third_block.list.first" values={{ 
                        span: (...chunks) => <span>{chunks}</span>
                      }} />
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="item">
                    <div className="icon"></div>
                    <p><FormattedMessage defaultMessage={translations['en']['third_block.list.second']} id="third_block.list.second" /></p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="item">
                    <div className="icon"></div>
                    <p>
                      <FormattedMessage defaultMessage={translations['en']['third_block.list.third']} id="third_block.list.third" values={{ 
                        span: (...chunks) => <span>{chunks}</span>
                      }} />
                    </p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="item">
                    <div className="icon"></div>
                    <p><FormattedMessage defaultMessage={translations['en']['third_block.list.fourth']} id="third_block.list.fourth" /></p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="item">
                    <div className="icon"></div>
                    <p><FormattedMessage defaultMessage={translations['en']['third_block.list.fifth']} id="third_block.list.fifth" /></p>
                  </div>
                </div>
                <div className="col-12">
                  <div className="item">
                    <div className="icon"></div>
                    <p>
                      <FormattedMessage defaultMessage={translations['en']['third_block.list.sixth']} id="third_block.list.sixth" values={{ 
                        span: (...chunks) => <span>{chunks}</span>
                      }} />
                    </p>
                  </div>
                </div>
              </div>
  
            </div>
          </div>
        </div>
        <div onClick={moveSectionDown} className="mouse">
          <div style={{ width: lengthSelection.width, left: lengthSelection.margin }} className="left"></div>
          <div style={{ width: lengthSelection.width, right: lengthSelection.margin }} className="right"></div>
        </div>
      </section>
  
      <section className="vertical-scrolling" id='block_4'>
        <div className="container text-center">
          <h2>
            <FormattedMessage defaultMessage={translations['en']['fourth_block.list.fourth']} id="fourth_block.list.fourth" values={{ 
              span: (...chunks) => <span className='d-md-none'>{chunks}</span>,
              br: <br />
            }} />
          </h2>
          <img className="img-responsive" src={workScheme} alt="" />
        </div>
        <div onClick={moveSectionDown} className="mouse">
          <div style={{ width: lengthSelection.width, left: lengthSelection.margin }} className="left"></div>
          <div style={{ width: lengthSelection.width, right: lengthSelection.margin }} className="right"></div>
        </div>
      </section>
  
      <section className="vertical-scrolling" id='block_5'>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 col-custom">
              <div className="card">
                <div className="number">1</div>
                <div className="title"><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.first.title']} id="fifth_block.cards.first.title" /></div>
                <p><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.first.description']} id="fifth_block.cards.first.description" /></p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-custom">
              <div className="card">
                <div className="number">2</div>
                <div className="title"><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.second.title']} id="fifth_block.cards.second.title" /></div>
                <p><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.second.description']} id="fifth_block.cards.second.description" /></p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-custom">
              <div className="card">
                <div className="number">3</div>
                <div className="title"><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.third.title']} id="fifth_block.cards.third.title" /></div>
                <p>
                  <FormattedMessage defaultMessage={translations['en']['fifth_block.cards.third.description']} id="fifth_block.cards.third.description" values={{ 
                    br: <br />
                  }} />
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-custom">
              <div className="card">
                <div className="number">4</div>
                <div className="title"><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.fourth.title']} id="fifth_block.cards.fourth.title" /></div>
                <p><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.fourth.description']} id="fifth_block.cards.fourth.description" /></p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 col-custom">
              <div className="card">
                <div className="number">5</div>
                <div className="title"><FormattedMessage defaultMessage={translations['en']['fifth_block.cards.fifth.title']} id="fifth_block.cards.fifth.title" /></div>
                <p>
                  <FormattedMessage defaultMessage={translations['en']['fifth_block.cards.fifth.description']} id="fifth_block.cards.fifth.description" values={{ 
                    br: <br />
                  }} />
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="end">
                  <FormattedMessage defaultMessage={translations['en']['fifth_block.end']} id="fifth_block.end" values={{ 
                    span: (...chunks) => <span>{chunks}</span>,
                  }} />
              </div>
              <img className="img-responsive w-75" src={imgI05} alt="" />
            </div>
          </div>
        </div>
        <div onClick={moveSectionDown} className="mouse">
          <div style={{ width: lengthSelection.width, left: lengthSelection.margin }} className="left"></div>
          <div style={{ width: lengthSelection.width, right: lengthSelection.margin }} className="right"></div>
        </div>
      </section>
  
      <section className="vertical-scrolling" id='block_6'>
        <div className="container">
          <h2 className="text-center"><FormattedMessage defaultMessage={translations['en']['sixth_block.title']} id="sixth_block.title" /></h2>
          <div className="row">
            <div className="col-12 col-md-3 col-lg-4">
              <img className="img-responsive" src={logo} alt="" />
  
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.legal_name']} id="sixth_block.legal_name" /></span>
              <p>SIA "INTELTELECOM" (http://company.lursoft.lv/en/inteltelecom/40103947440)</p>
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.legal_form']} id="sixth_block.legal_form" /></span>
              <p>Limited responsibility company</p>
  
  
            </div>
            <div className="col-12 col-md-6 col-lg-4">
  
  
  
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.reg_number']} id="sixth_block.reg_number" /></span>
              <p>40103947440, 17.11.2015</p>
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.reg_office']} id="sixth_block.reg_office" /></span>
              <p>Marijas idea 1-3,Riga, LV-1050</p>
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.vat_number']} id="sixth_block.vat_number" /></span>
              <p>LV40103947440</p>
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.contact_number']} id="sixth_block.contact_number" /></span>
              <p>+380931122347</p>
  
  
            </div>
            <div className="col-12 col-md-6 col-lg-4">
  
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.email']} id="sixth_block.email" /></span>
              <p>Sg@intel-tele.com</p>
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.detail_price']} id="sixth_block.detail_price" /></span>
              <p>Sg@intel-tele.com</p>
              <span><FormattedMessage defaultMessage={translations['en']['sixth_block.bank_detail']} id="sixth_block.bank_detail" /></span>
              <p>LV38BLIB1001027712001 Baltic International Bank Kaleju St.43, Riga, LV-1050, Latvia BLIBLV22</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Sections;
